<template>
  <div class="setting">
    <!-- <h3>配置页面</h3> -->
    <div class="item">
      <span>是否允许游客登录&nbsp;&nbsp;&nbsp;</span>
      <el-switch
        v-model="settingInfo.tourist"
        @change="updateSettings"
        active-color="#13ce66"
        inactive-color="#999"
        :active-value="1"
        :inactive-value="0"
      >
      </el-switch>
    </div>
    <div class="item">
      <span>是否允许微信登录&nbsp;&nbsp;&nbsp;</span>
      <el-switch
        v-model="settingInfo.wechatLogin"
        @change="updateSettings"
        active-color="#13ce66"
        inactive-color="#999"
        :active-value="1"
        :inactive-value="0"
      >
      </el-switch>
    </div>
    <div class="item">
      <span>是否对接微信支付&nbsp;&nbsp;&nbsp;</span>
      <el-switch
        v-model="settingInfo.payment"
        @change="updateSettings"
        active-color="#13ce66"
        inactive-color="#999"
        :active-value="true"
        :inactive-value="false"
      >
      </el-switch>
    </div>
    <div class="item">
      <span>预约是否线上支付&nbsp;&nbsp;&nbsp;</span>
      <el-switch
        v-model="settingInfo.reserveIsPayment"
        @change="updateSettings"
        active-color="#13ce66"
        inactive-color="#999"
        :active-value="true"
        :inactive-value="false"
      >
      </el-switch>
    </div>
    <div class="item">
      <span>用户端是否显示预约模块&nbsp;&nbsp;&nbsp;</span>
      <el-switch
        v-model="settingInfo.isReserve"
        @change="updateSettings"
        active-color="#13ce66"
        inactive-color="#999"
        :active-value="true"
        :inactive-value="false"
      >
      </el-switch>
    </div>
    <div class="item">
      <span>登录页面&nbsp;&nbsp;&nbsp;</span>
      <el-select
        size="small"
        v-model="settingInfo.loginPage"
        placeholder="请选择"
      >
        <el-option label="心博士版" :value="1"> </el-option>
        <el-option label="源爱版" :value="2"> </el-option>
      </el-select>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 10px"
        @click="updateSettings"
        >确定</el-button
      >
    </div>
    <div class="item">
      <span>报告底部样式&nbsp;&nbsp;&nbsp;</span>
      <el-select
        size="small"
        v-model="settingInfo.reportStyle"
        placeholder="请选择"
      >
        <el-option label="心博士版" :value="false"> </el-option>
        <el-option label="源爱版" :value="true"> </el-option>
      </el-select>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 10px"
        @click="updateSettings"
        >确定</el-button
      >
    </div>
    <div class="item">
      <span>微信注册用户所属&nbsp;&nbsp;&nbsp;</span>
      <el-cascader
        size="small"
        :options="organizationList"
        :props="{
          checkStrictly: true,
          label: 'name',
          value: 'id',
          emitPath: false,
        }"
        clearable
        v-model="settingInfo.weChatUser"
        :show-all-levels="false"
      ></el-cascader>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 10px"
        @click="updateSettings"
        >确定</el-button
      >
    </div>
    <div class="item">
      <span>游客转用户所属组织机构&nbsp;&nbsp;&nbsp;</span>
      <el-cascader
        size="small"
        :options="organizationList"
        :props="{
          checkStrictly: true,
          label: 'name',
          value: 'id',
          emitPath: false,
        }"
        clearable
        v-model="settingInfo.touristToUser"
        :show-all-levels="false"
      ></el-cascader>
      <el-button
        type="primary"
        size="mini"
        style="margin-left: 10px"
        @click="updateSettings"
        >确定</el-button
      >
    </div>
    <div class="item">
      <div class="male">
        <span>咨询师默认头像（男）&nbsp;&nbsp;&nbsp;</span>
        <el-upload
          class="avatar-uploader"
          :action="$store.state.requestApi + '/gauge/upload'"
          :data="{
            id: 99999,
            type: 4,
          }"
          :headers="{ token }"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
        >
          <img
            v-if="settingInfo.consultUrlMale"
            :src="settingInfo.consultUrlMale"
            class="avatar"
          />
          <span class="img" v-else>未上传</span>
        </el-upload>
      </div>
      <div class="female">
        <span>咨询师默认头像（女）&nbsp;&nbsp;&nbsp;</span>
        <el-upload
          class="avatar-uploader"
          :action="$store.state.requestApi + '/gauge/upload'"
          :data="{
            id: 99998,
            type: 4,
          }"
          :headers="{ token }"
          :show-file-list="false"
          :on-success="handleAvatarSuccessFemale"
        >
          <img
            v-if="settingInfo.consultUrlFemale"
            :src="settingInfo.consultUrlFemale"
            class="avatar"
          />
          <span class="img" v-else>未上传</span>
        </el-upload>
      </div>
    </div>
    <div class="item"></div>
  </div>
</template>

<script>
import { getSetting, updateSetting, getOrganization } from "@/api/setting";
export default {
  data() {
    return {
      settingInfo: {
        tourist: null,
        weChatUser: null,
        wechatLogin: null,
        touristToUser: null,
        isReserve: false,
        payment: false,
        consultUrlMale: "",
        consultUrlFemale: "",
      },

      token: null,
      organizationList: [], //组织机构列表
    };
  },
  methods: {
    // 获取配置信息
    async getLogs() {
      const res = await getSetting();
      console.log(res);
      if (res.code === 200) {
        this.settingInfo = res.data;
      }
    },
    // 修改配置信息
    async updateSettings() {
      console.log(this.settingInfo);
      const res = await updateSetting(JSON.stringify(this.settingInfo));
      console.log(res);
      if (res.code === 200) {
        this.$message({
          showClose: true,
          message: "修改成功！",
          type: "success",
        });
        this.getLogs();
      } else {
        this.$message({
          showClose: true,
          message: "修改失败！" + res.msg,
          type: "error",
        });
        this.getLogs();
      }
    },
    // 获取组织结构列表
    async getOrganizationList() {
      const res = await getOrganization();
      console.log(res);
      if (res.code === 200) {
        this.organizationList = res.data;
      }
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      console.log(res);
      if (res.code === 200) {
        this.settingInfo.consultUrlMale = res.data;
        this.updateSettings();
      }
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
    // 上传图片
    handleAvatarSuccessFemale(res, file) {
      console.log(res);
      if (res.code === 200) {
        this.settingInfo.consultUrlFemale = res.data;
        this.updateSettings();
      }
      // this.imageUrl = URL.createObjectURL(file.raw);
    },
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getLogs();
    this.getOrganizationList();
  },
};
</script>

<style lang="scss" scope>
.setting {
  padding: 30px;

  .item {
    margin-bottom: 15px;
    display: flex;

    span {
      font-size: 16px;
    }

    .el-button {
      height: auto;
    }

    .male,
    .female {
      display: inline-block;
    }
    // .female{
    //     display: inline-block;
    // }
    .img {
      // margin: auto;
      width: 130px;
      height: 130px;
      display: inline-block;
      border-radius: 15px;
      // background-color: antiquewhite;
      box-shadow: 1px 1px 1px 1px rgb(189, 188, 188);
    }

    img {
      width: 130px;
      height: 130px;
      display: inline-block;
      border-radius: 15px;
    }
  }
}
</style>